import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthButton from '../ui-elements/AuthButton';
import Logo from '../ui-elements/Logo';
import { FaHome, FaTicketAlt, FaUser, FaPlus } from 'react-icons/fa';

const Menu: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setIsOpen(!isOpen);

  const menuItems = [
    { path: '/', label: 'Home', icon: FaHome },
    { path: '/tickets', label: 'Tickets', icon: FaTicketAlt, requireAuth: true },
    { path: '/profile', label: 'Profile', icon: FaUser, requireAuth: true },
    { path: '/create-ticket', label: 'Create Ticket', icon: FaPlus, requireAuth: true },
  ];

  const filteredMenuItems = menuItems.filter((item) => !item.requireAuth || isAuthenticated);

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const menuItemStyle = (active: boolean) =>
    `px-4 py-3 rounded-md text-base font-medium transition-all duration-300 ease-in-out flex items-center ${
      active
        ? 'bg-cerulean text-cirrus shadow-md'
        : 'text-cirrus hover:bg-cool-grey-01 hover:text-cerulean hover:scale-105'
    }`;

  return (
    <nav className="bg-storm shadow-lg fixed top-0 left-0 right-0 h-16 z-50">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center flex-grow">
            <div className="flex-shrink-0">
              <Link to="/">
                <Logo name="Ekwis-Horizontal-White" className="h-12 w-auto" />
              </Link>
            </div>
            <div className="hidden lg:block ml-4">
              <div className="flex items-baseline space-x-6">
                {filteredMenuItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={menuItemStyle(isActive(item.path))}
                  >
                    <item.icon className="mr-2" />
                    {item.label}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <AuthButton />
          </div>
          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-cirrus hover:text-cerulean hover:bg-cool-grey-01 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cool-grey-01 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div 
        className={`${
          isOpen ? 'block' : 'hidden'
        } lg:hidden bg-storm absolute top-16 left-0 right-0 shadow-lg`} 
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {filteredMenuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={menuItemStyle(isActive(item.path))}
              onClick={() => setIsOpen(false)}
            >
              <item.icon className="mr-2" />
              {item.label}
            </Link>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-cool-grey-01">
          <div className="px-2">
            <AuthButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
