import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDataContext } from '../App';
import { createCaseWithEmail } from '../api/cases';
import LoadingSpinner from '../components/ui-elements/LoadingSpinner';
import PageLayout from '../components/layout/PageLayout';
import Toast from '../components/ui-elements/Toast';
import { FaEnvelope } from 'react-icons/fa';
import NoContactFound from '../components/ui-elements/NoContactFound';
import { FormInput } from '../components/ui-elements/FormInput';
import { RichTextEditorWithLabel } from '../components/ui-elements/RichTextEditorWithLabel';
import { FormActions } from '../components/ui-elements/FormActions';
import { extractPlainText } from '../utils/textUtils';
import { Auth0ContextInterface } from '@auth0/auth0-react';

type FormData = {
  name: string;
  email: string;
  subject: string;
  contactId?: string;
  accountId?: string;
};

const CreateTicket: React.FC = () => {
  const { isLoading: authLoading, getAccessTokenSilently }: Auth0ContextInterface = useAuth0();
  const { contactRecord, accountRecord, loading: dataLoading, noContactFound } = useDataContext();
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    subject: '',
    contactId: '',
    accountId: '',
  });
  const [toastConfig, setToastConfig] = useState<{ title: string; message?: string; type?: 'success' | 'error' | 'info' } | null>(null);
  const [description, setDescription] = useState('');
  const [editorKey, setEditorKey] = useState('0');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (contactRecord && accountRecord) {
      setFormData(prevState => ({
        ...prevState,
        name: `${contactRecord.FirstName || ''} ${contactRecord.LastName || ''}`.trim(),
        email: contactRecord.Email || '',
        contactId: contactRecord.Id,
        accountId: accountRecord.Id,
      }));
    }
  }, [contactRecord, accountRecord]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setToastConfig(null);
    setIsSubmitting(true);
    setError(null);

    const textContent = extractPlainText(description);

    if (!formData.subject.trim() || !textContent) {
      showToast("Error", "Subject and description are required.", "error");
      setIsSubmitting(false);
      return;
    }

    if (!formData.contactId || !formData.accountId) {
      console.error('Contact or Account ID not found');
      showToast("Error", "Contact or Account ID not found", "error");
      setIsSubmitting(false);
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      const caseData = {
        ContactId: formData.contactId,
        AccountId: formData.accountId,
        Subject: formData.subject,
        Description: description,
        FromAddress: formData.email,
        FromName: formData.name,
      };

      const result = await createCaseWithEmail(token, caseData);
      console.log('Case created and email added:', result);

      showToast("Success", "Ticket created successfully!", "success");
      clearForm();
    } catch (err) {
      console.error('Error creating case and adding email:', err);
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      showToast("Error", "Error creating ticket. Please try again.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const clearForm = () => {
    setFormData(prevState => ({
      ...prevState,
      subject: '',
    }));
    setDescription('');
    setEditorKey(prevKey => (parseInt(prevKey) + 1).toString());
  };

  const showToast = (title: string, message?: string, type: 'success' | 'error' | 'info' = 'success') => {
    setToastConfig({ title, message, type });
  };

  if (authLoading || dataLoading) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (noContactFound) {
    return <NoContactFound pageTitle="Create Ticket" />;
  }

  return (
    <PageLayout pageTitle="Create a New Ticket" pageIcon={FaEnvelope}>
      <div className="relative">
        {isSubmitting && (
          <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-50">
            <LoadingSpinner message="Submitting ticket..." />
          </div>
        )}
        {toastConfig && (
          <Toast
            title={toastConfig.title}
            message={toastConfig.message}
            type={toastConfig.type}
            onClose={() => setToastConfig(null)}
          />
        )}
        {error && <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">{error}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormInput
              id="name"
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              required
              disabled
            />
            <FormInput
              id="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled
              type="email"
            />
          </div>
          <FormInput
            id="subject"
            name="subject"
            label="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
            disabled={isSubmitting}
            placeholder="Enter ticket subject"
          />
          <RichTextEditorWithLabel
            label="Description"
            content={description}
            onUpdate={setDescription}
            editorKey={editorKey}
            disabled={isSubmitting}
            required
          />
          <FormActions
            onClear={clearForm}
            onSubmit={handleSubmit} // Now matches the expected signature
            isSubmitting={isSubmitting}
            submitText="Submit Ticket"
          />
        </form>
      </div>
    </PageLayout>
  );
};

export default CreateTicket;
