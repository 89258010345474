import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import ProfilePage from './pages/ProfilePage';
import Home from './pages/Home';
import Tickets from './pages/Tickets';
import CreateTicket from './pages/CreateTicket';
import { fetchContactByEmail } from './api/contacts';
import { fetchAccountRecord } from './api/accounts';
import { fetchAuthData } from './api/auth';
import { getEnvVariables } from './utils/env';
import axios from 'axios';
import Menu from './components/navigation/Menu';
import Footer from './components/layout/Footer';
import { useMediaQuery } from '@mui/material';
import UnauthenticatedUser from './pages/UnauthenticatedUser';
import LoadingSpinner from './components/ui-elements/LoadingSpinner';
import CaseDetailsPage from './pages/CaseDetailsPage';

// Add DeviceType to the GlobalState interface
interface GlobalState {
  contactRecord: any;
  accountRecord: any;
  authData: any;
  loading: boolean;
  error: string | null;
  noContactFound: boolean;
  deviceType: 'mobile' | 'tablet' | 'desktop';
}

const DataContext = createContext<GlobalState | undefined>(undefined);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export const DataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, user, isLoading: authLoading, logout } = useAuth0();
  const [state, setState] = useState<GlobalState>({
    contactRecord: null,
    accountRecord: null,
    authData: null,
    loading: true,
    error: null,
    noContactFound: false,
    deviceType: 'desktop', // Default to desktop
  });

  // Use Material-UI's useMediaQuery hook to determine device type
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:960px)');

  useEffect(() => {
    // Update deviceType based on screen size
    if (isMobile) {
      setState(prev => ({ ...prev, deviceType: 'mobile' }));
    } else if (isTablet) {
      setState(prev => ({ ...prev, deviceType: 'tablet' }));
    } else {
      setState(prev => ({ ...prev, deviceType: 'desktop' }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet]);

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated && user?.email && !authLoading) {
        setState(prev => ({ ...prev, loading: true, error: null }));
        try {
          const token = await getAccessTokenSilently();

          // Fetch contact data
          console.log(`Fetching contact for email: ${user.email}`);
          const contactResponse = await fetchContactByEmail(token, user.email);
          const contactData = contactResponse.data[0];

          console.log('Contact Response:', contactResponse);

          if (!contactData) {
            // No contact found
            setState(prev => ({
              ...prev,
              loading: false,
              noContactFound: true,
            }));
            return; // Exit early
          }

          // Fetch account data
          console.log(`Fetching account with ID: ${contactData.AccountId}`);
          const accountResponse = await fetchAccountRecord(token, contactData.AccountId);
          const accountData = accountResponse.data;

          // Fetch Auth0 data
          const authData = await fetchAuthData(token);
          console.log('Auth Data:', authData);

          setState(prev => ({
            ...prev,
            contactRecord: contactData,
            accountRecord: accountData,
            authData: authData,
            loading: false,
            error: null,
            noContactFound: false,
          }));
        } catch (error: any) {
          console.error('Error fetching data:', error);
          if (axios.isAxiosError(error)) {
            console.error('Axios error details:', {
              message: error.message,
              response: error.response?.data,
              status: error.response?.status,
              config: error.config
            });

            // Check if the error is a 404 Not Found
            if (error.response?.status === 404) {
              console.log('Contact not found, setting noContactFound to true.');
              setState(prev => ({
                ...prev,
                loading: false,
                noContactFound: true,
              }));
              return; // Exit early
            }
          }

          setState(prev => ({
            ...prev,
            loading: false,
            error: 'An error occurred while fetching data. Please contact support@ekwis.com, include the email address used to login to this portal.',
          }));
        }
      } else if (!authLoading) {
        setState(prev => ({ ...prev, loading: false }));
      }
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently, user, authLoading]);

  if (state.noContactFound) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-cool-grey-02">
        <div className="bg-white shadow-lg rounded-lg p-6 max-w-md w-full">
          <div className="flex items-center mb-4">
            <svg className="w-6 h-6 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
            </svg>
            <h2 className="text-xl font-semibold text-gray-800">Account Not Found</h2>
          </div>
          <p className="text-gray-600 mb-4">
            We couldn't find an account associated with your email address. This could be because:
          </p>
          <ul className="list-disc list-inside text-gray-600 mb-4">
            <li>You're using a different email than the one registered with us</li>
            <li>Your account hasn't been set up in our system yet</li>
          </ul>
          <p className="text-gray-600 mb-6">
            Please contact our support team at <a href="mailto:support@ekwis.com" className="text-blue-500 hover:underline">support@ekwis.com</a> for assistance. Include the email address you used to log in.
          </p>
          <button
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin
                }
              });
            }}
            className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            Logout
          </button>
        </div>
      </div>
    );
  }

  return (
    <DataContext.Provider value={state}>
      {children}
    </DataContext.Provider>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner message="Checking authentication..." />;
  }

  return isAuthenticated ? children : <Navigate to="/unauthenticated" replace />;
};

const AppContent: React.FC = React.memo(() => {
  return (
    <DataProvider>
      <Router>
        <div className="flex flex-col min-h-screen max-w-full overflow-x-hidden">
          <Menu />
          <div className="flex-1 mt-16 mb-16 w-full overflow-x-auto">
            <Routes>
              <Route path="/unauthenticated" element={<UnauthenticatedUser />} />
              <Route 
                path="/" 
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/tickets/*" 
                element={
                  <ProtectedRoute>
                    <Tickets />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/profile/*" 
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/create-ticket" 
                element={
                  <ProtectedRoute>
                    <CreateTicket />
                  </ProtectedRoute>
                } 
              />
              <Route 
                path="/cases/:id" 
                element={
                  <ProtectedRoute>
                    <CaseDetailsPage />
                  </ProtectedRoute>
                } 
              />
              {/* ... other routes ... */}
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </DataProvider>
  );
});

const App: React.FC = () => {
  const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE } = getEnvVariables();

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN!}
      clientId={AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
      }}
    >
      <div className="bg-cool-grey-02 min-h-screen max-w-full overflow-x-hidden">
        <AppContent />
      </div>
    </Auth0Provider>
  );
};

export default App;
